<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Liste des demandes articles</div>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    href="#"
                    id="commande"
                    @click.prevent="showCommandes()"
                    >Les commandes ({{ count_commande }})</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link act"
                    href="#"
                    id="echantillon"
                    @click.prevent="showEchantill()"
                    >Les echantillons ({{ count_echant }})</a
                  >
                </li>
              </ul>
                <!-- commande -->

              <div class="table-responsive" v-if="type == 'commande'">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Code du commande</th>
                      <th scope="col">Etat du commande</th>
                      <th scope="col">Date commande</th>
                      <th scope="col">Client</th>
                      <th scope="col">Le damandeur</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(commande, index) in searchFunction"
                      :key="index"
                    >
                      <th>{{ commande.numero }}</th>
                      <td>
                        <span
                          class="badge rounded-pill bg-info"
                          v-if="commande.etat_commande == 'en preparation'"
                          >Commande en preparation</span
                        >
                        <span
                          class="badge rounded-pill bg-success"
                          v-if="commande.etat_commande == 'valide_magasinier'"
                          >Commande validé</span
                        >
                        <span
                          class="badge rounded-pill bg-warning"
                          v-if="commande.etat_commande == 'attente_stock'"
                          >Commande en attente du stock</span
                        >
                      </td>

                      <td>{{ commande.date_commande | formatDate }}</td>
                      <td>{{ commande.client_id["name"] }}</td>
                      <td>{{ commande.user_id["name"] }}</td>

                      <td>
                        <router-link :to="{
                          name:'showdemandescommandes',
                          params:{id:commande._id },
                        }">
                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
            
                        >
                        </i>
                      </router-link>
                        <i
                        class="mdi mdi-briefcase-check icone text-success"
                          title="Valider la commande"
                          @click="valide(commande._id)"
                          v-if="
                            commande.etat_commande == 'en preparation' ||
                            commande.etat_commande == 'attente_stock'
                          "
                        >
                        </i>
                        <i
                          class="mdi mdi-clock-end icone text-danger"
                          title="Commande en attente"
                          @click="attente(commande._id)"
                          v-if="commande.etat_commande == 'en preparation'"
                        >
                      </i>
                        <!--Button trigger modal--> 

                        <!-- Modal -->
                        <div
                          class="modal fade"
                          :id="'test' + commande._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                  <span style="font-size: 13px"
                                    >Commande No =</span
                                  >
                                  <b>{{ commande.numero }}</b>
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les articles demandé:</h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Reference</th>
                                      <th>Quantité demandé</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        produit, index
                                      ) in commande.produits"
                                      :key="index"
                                    >
                                      <td>
                                        {{ produit.produit_id.reference }}
                                      </td>
                                      <td>{{ produit.quantites }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                  
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                <!-- echantillon -->

              <div class="table-responsive" v-if="type == 'echantillon'">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search_echant"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Delegué</th>
                      <th scope="col">Etat du demande</th>
                      <th scope="col">date du demande</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(echantillon, index) in searchFunctionEchant"
                      :key="index"
                    >
                      <th>{{ echantillon.user_id["name"] }}</th>
                      <td>
                        <span
                          class="badge rounded-pill bg-secondary"
                          v-if="echantillon.etat_demmande == 'en attente'"
                          >Demande en attente</span
                        >

                        <span
                          class="badge rounded-pill bg-info"
                          v-if="echantillon.etat_demmande == 'en preparation'"
                          >Demande en preparation</span
                        >

                        <span
                          class="badge rounded-pill bg-danger"
                          v-if="echantillon.etat_demmande == 'refuse'"
                          >Demande refusé</span
                        >
                        <span
                          class="badge rounded-pill bg-success"
                          v-if="
                            echantillon.etat_demmande == 'valide_magasinier'
                          "
                          >Demande validé</span
                        >
                        <span
                          class="badge rounded-pill bg-warning"
                          v-if="echantillon.etat_demmande == 'attente_stock'"
                          >Demande en attente du stock</span
                        >
                      </td>

                      <td>{{ echantillon.created_at | formatDate }}</td>

                      <td>
                        <router-link :to="{
                          name:'showechantillondemmande',
                          params:{id:echantillon._id },
                        }">

                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                        >
                        </i>
                        </router-link>
                        <div
                          class="modal fade"
                          :id="'test' + echantillon._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5>{{ echantillon.user_id["name"] }}</h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les échantillons demandés</h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Reference</th>
                                      <th>Quantité demandé</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        produit, index
                                      ) in echantillon.produits"
                                      :key="index"
                                    >
                                      <td>
                                        {{ produit.produit_id.reference }}
                                      </td>
                                      <td>{{ produit.quantites }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Save changes
                                  </button>
                                </div> -->
                            </div>
                          </div>
                        </div>

                        <i
                          class="mdi mdi-briefcase-check icone text-success"
                          title="Valider la commande"
                          @click="valideEchantillon(echantillon._id)"
                          v-if="echantillon.etat_demmande == 'en preparation'||
                            echantillon.etat_demmande == 'attente_stock'"

                        >
                        </i>
                            
                          <i
                          class="mdi mdi-clock-end icone text-danger"
                            title="Refuser la commande"
                            @click.prevent="attenteEchantillon(echantillon._id)"
                            v-if="echantillon.etat_demmande == 'en preparation'"
                          >
                          </i>
                        <!-- Button trigger modal -->

                        <!-- Modal -->

                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  data() {
    return {
      commandes: [],
      echantillons: [],
      search: "",
      search_echant: "",
      type: "",
      count_commande: 0,
      count_echant: 0,
    };
  },
  mounted() {
    this.type = "commande";
  },

  created() {
    this.getcommandes();
    this.getechantillons();
  },
  computed: {
    searchFunction() {
      return this.commandes.filter((item) => {
        return (
          item.numero.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    searchFunctionEchant() {
      return this.echantillons.filter((item) => {
        return (
          item._id.toLowerCase().indexOf(this.search_echant.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    //echantillons
    showEchantill() {
      $("#commande").removeClass("active");
      $("#echantillon").addClass("active");
      this.type = "echantillon";
    },

    getechantillons() {
      HTTP.get("echantillons/getEchantillonspreparation")
        .then((response) => {
          this.echantillons = response.data;
          this.echantillons.forEach((echantillon) => {
            if (echantillon.etat_demmande == "en preparation" || echantillon.etat_demmande == "attente_stock") {
              this.count_echant++;
            }
          });
        })
        .then((err) => console.log(err));
    },

    valideEchantillon(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez valider la demande",
        icon: "success",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("echantillons/alimenterechantillon/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "demande validé par magasinier",
            });

            this.getechantillons();
            this.count_echant--;
          });
        }
      });
    },

    attenteEchantillon(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez mettre cette dommande en attente",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, en attente!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("echantillons/echantillonattente/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "demande d'echantillons en attente",
            });

            this.getechantillons();
            this.count_echant--;
          });
        }
      });
    },

    //commandes

    showCommandes() {
      $("#echantillon").removeClass("active");
      $("#commande").addClass("active");
      this.type = "commande";
    },

    getcommandes() {
      HTTP.get("commandes/commandeprepatation")
        .then((response) => {
          this.commandes = response.data;

          this.commandes.forEach((commande) => {
            if (commande.etat_commande == "en preparation"||commande.etat_commande == "attente_stock") {
              this.count_commande++;
            }
          });
        })
        .then((err) => console.log(err));
    },
    valide(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez alimenter la commande",
        icon: "success",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("commandes/alimentercommande/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "commande validé par magasinier",
            });

            this.getcommandes();
            if(this.count_commande>0){
            this.count_commande--;

            } 
          });
        }
      });
    },

    attente(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez mettre cette commande en attente",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, en attente!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("commandes/commandeattente/" + id).then((response) => {
            //this.$htmlToPaper("printNow");

            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "commande en attente",
            });

            this.getcommandes();
            if(this.count_commande>0){
            this.count_commande--;

            } 
          });
        }
      });
    },
  },
};
</script>
